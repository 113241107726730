<template>
  <b-card header="Add Topic">
    <topic-form
      :submit="addTopic"
      :topic.sync="topic"
    />

    <upload-progress
      class="mt-1"
      :progress="progress"
    />
  </b-card>
</template>
<script>
import handleAlerts from '@/common/compositions/common/handleAlerts'
import TopicForm from '@/common/components/TopicsCalendar/TopicForm.vue'
import uploadPercentage from '@/common/compositions/common/uploadPercentage'
import UploadProgress from '@/common/components/common/FormInputs/UploadProgress.vue'

export default {
  name: 'AddTopic',
  components: { TopicForm, UploadProgress },
  data() {
    return {
      topic: { entityId: null },
    }
  },
  setup() {
    const { successfulOperationAlert } = handleAlerts()
    const { progress, calculateUploadPercentage } = uploadPercentage()
    return {
      successfulOperationAlert,

      progress,
      calculateUploadPercentage,
    }
  },
  methods: {
    addTopic() {
      return this.$activities.post('/internalops/weekly/calendar/topics', { ...this.topic, image: this.topic.image[0] }, {
        onUploadProgress: progressEvent => { this.calculateUploadPercentage(progressEvent) },
      }).then(() => {
        this.successfulOperationAlert('Topic is added successfully')
        this.$router.push({ name: 'topics-calendar-list' })
      })
    },
  },
}
</script>
<style lang="scss">

</style>
